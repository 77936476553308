(function ( $ ) {
     $.fn.ajaxLink = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink    = $(this);
        var scrollTop  = 0;

        function _init() {
            objLink.each(function (index) {
                _clickProduct($(this));
                _disableLinks($(this));
            });
            _closeBtn();
        }

        function _disableLinks(o){
            $(o).find('a').click(function(e) {
                e.preventDefault();
                return true;
            });
        }

        function _clickProduct(o){
            $(o).click(function() {
                _loadProduct(o);
            });
        }

        function _closeBtn(){
            $('#product-detail a.close').click(function(){
                $('body').removeClass('locked');
                $('html, body').animate({
                      scrollTop: scrollTop
                }, 0);
                $('#product-detail').removeClass('active loading loaded');
                $('#product-detail .content').html('');

                $('#group-detail').removeClass('active loading loaded');
                $('#group-detail .content').html('');
            });
        }

        function _loadProduct(o){
            scrollTop = $(document).scrollTop();
            $('#product-detail').addClass('active');
            setTimeout(function(){ $('#product-detail').addClass('loading') }, 10);
            setTimeout(function(){ $('body').addClass('locked'); }, 900);

            $.ajax({
                url : ajaxurl,
                data : {'action': 'product_detail', 'ids': $(o).attr("data-id") },
                type : 'POST',
                success : function( data ){
                    if(data.html != null){
                        $('#product-detail .content').html(data.html);
                        $('#product-detail').addClass('loaded');
                        $('#share .container').html(data.share);
                        _initPhotogalery();
                        _initFeatured();
                        _initShare();
                        _initFormFocuses();
                    }
                }
            });
        }

        function _initFormFocuses(){
            forms = $('body').find('.gform_wrapper');
            //forms = $(forms).find('.form');
            $(forms).find('input[type="text"],input[type="password"],textarea').each(function( index ) {
                $(this).on("focus", function() {
                    $(this).closest('li').addClass('focus filled');
                }).on("blur", function() {
                    $(this).closest('li').removeClass('focus');
                    val = $(this).val();
                    if(val == ''){
                        $(this).closest('li').removeClass('filled');
                    }
                });
            });
        }


        function _initShare(){
            $('#product-detail .contacts-line .share').click(function(){
                $('#share').addClass('active');
            });
            $('#share a.close').click(function(){
                $('#share').removeClass('active');
            });

            $('#share .container .link button').click(function(){

                var $tempElement = $("<input>");
                $("body").append($tempElement);
                $tempElement.val($('#share .container .link span').text().trim()).select();
                document.execCommand("Copy");
                $tempElement.remove();

                $(this).html('Kopirováno').addClass('copied');
            });
        }

        function _initFeatured(){
            var owlFeatured = $('.owl-featured');

            owlFeatured.owlCarousel({
                loop: false,
                margin: 25,
                nav: true,
                dots: true,
                navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
                responsive : {
                    0 : {
                        items: 1
                    },
                    600 : {
                        items: 2
                    },
                    1100 : {
                        items: 3
                    }
                },
                autoplay:false
            });
        }

        function _initPhotogalery(){
            var owlPhotos = $('.owl-photogalery');
            owlPhotos.owlCarousel({
                loop: false,
                margin: 25,
                nav: true,
                dots: true,
                navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
                responsive : {
                    0 : {
                        items: 1
                    },
                    768 : {
                        items: 2
                    },
                    880 : {
                        items: 3
                    },
                    1100 : {
                        items: 4
                    }
                },
                autoplay:false
            });
            if($(window).width() > 770){
                $('.head .img').magnificPopup({
                    type: 'image',
                    delegate: 'a',
                    gallery: {
                        enabled: true
                    }
                });
            }else{
                $('.head .img a').click(function(e) {
                    e.preventDefault();
                    return true;
                });
            }
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

(function ( $ ) {
 $.fn.ajaxLoader = function (options) {
     var settings = $.extend({
        someOption: ""
    }, options);

    var ajaxLoader    = $(this);
    var ajaxContainer = $(this).find('.ajaxConteiner');
    var ajaxMoreBtn   = $(this).find('.ajaxMoreProducts');


     //function definitions:
    function _init() {
        ajaxMoreBtn.click(function() {
            _loadMore();
        });
        _initFilter();
    }

    function _initFilter(){
        $('#catalog-filter .input ul a').each(function (index) {
            $(this).click(function() {
                $('#catalog-filter .select strong').html($(this).html());
                $('#catalog-filter  input[name="cat"]').attr('value',$(this).attr('data-id'));
                _loadFilter();
            });
        });
        $('#catalog-filter .bt button').click(function() {
            val = $('#catalog-filter  input[name="key"]').val();
            if(val != ''){
                $('#catalog-filter .input').addClass('loaded');
            }
            _loadFilter();
        });
        $('#catalog-filter .inp .removeSearch').click(function() {
            $('#catalog-filter .input').removeClass('loaded');
            //$('#catalog-filter  input[name="cat"]').attr('value','');
            //$('#catalog-filter  input[name="key"]').attr('value','');
            $('#catalog-filter  input[name="key"]').val('');
            _loadFilter();
        });
    }

    function _loadFilter(){
        action = 'products_more';
        paged  = '';
        cat  = $('#catalog-filter  input[name="cat"]').val();
        key  = $('#catalog-filter  input[name="key"]').val();

        ajaxContainer.html('').addClass('loading');
        ajaxMoreBtn.attr('data-call',action)
                   .attr('data-param',cat);

        $.ajax({
            url : ajaxurl,
            data : {'action': action, 'paged': paged, 'key': key, 'cat': cat },
            type : 'POST',
            success : function( data ){
                if(data.html != null){
                    ajaxContainer.removeClass('loading').append(data.html);
                    $('.ajaxLinks').ajaxLink().reload();
                }
                if(data.paged > 0){
                    ajaxMoreBtn.attr('data-page',data.paged);
                    $('.ajaxBtn').removeClass('hidden');
                }else{
                    $('.ajaxBtn').addClass('hidden');
                }
            }
        });
        return this;
    }

    function _loadMore() {
        action = ajaxMoreBtn.attr('data-call');
        paged  = ajaxMoreBtn.attr('data-page');
        param  = ajaxMoreBtn.attr('data-param');
        search = ajaxMoreBtn.attr('data-search');

        $('.ajaxBtn').addClass('loading');
        $.ajax({
            url : ajaxurl,
            data : {'action': action, 'paged': paged, 'param': param, 'search': search },
            type : 'POST',
            success : function( data ){
                if(data.html != null){
                    ajaxContainer.append(data.html);
                    $('.ajaxLinks').ajaxLink().reload();
                }
                if(data.paged != null && data.paged > 0){
                    ajaxMoreBtn.attr('data-page',data.paged);
                    $('.ajaxBtn').removeClass('hidden');
                }else{
                    $('.ajaxBtn').addClass('hidden');
                }
                $('.ajaxBtn').removeClass('loading');
            }
        });
        return this;
    }

    this.each(function () {
        _init();
    });

   return {
     reload:_init,
   };
};
}( jQuery ));


(function ( $ ) {
 $.fn.ajaxWhisper = function (options) {

    var inputContainer  = $(this);
    var input           = $(this).find('input');
    var btn             = $(this).find('button');


     //function definitions:
    function _init() {
        input.keyup(function() {
            if(input.val().length > 2){
                _initWhisper(input);
            }
        });

        input.on("focus", function() {
            $(this).closest(".input").addClass("focused");
            if($('.whisper').html().length > 0){
                $('.whisper').addClass('active');
            }
        }).on("blur", function() {
            $(this).closest(".input").removeClass("focused");
            setTimeout(function(){ $('.whisper').removeClass('active');}, 1000);
        })

        _initCloseBtn();
        _initSearchBtn();
    }

    function _initCloseBtn(){
        $('.removeSearch').click(function() {
            inputContainer.removeClass('loaded');
            $('.whisper').html('').removeClass('active');
            input.val('');
            btn.trigger( "click" );
        });
    }

    function _initSearchBtn(){
        btn.click(function() {

            action = 'products_more';
            paged  = '';
            param  = '';
            search = input.val();

            $('.ajaxConteiner').html('').addClass('loading');
            $('.ajaxBtn a').attr('data-call',action)
                       .attr('data-search',search);

            $.ajax({
                url : ajaxurl,
                data : {'action': action, 'paged': paged, 'param': param, 'search': search },
                type : 'POST',
                success : function( data ){
                    if(data.html != null){
                        $('.ajaxConteiner').removeClass('loading').append(data.html);
                        $('.ajaxLinks').ajaxLink().reload();
                    }
                    if(data.paged != null && data.paged > 0){
                        console.log(data.paged);
                        $('.ajaxBtn a').attr('data-page',data.paged);
                        $('.ajaxBtn').removeClass('hidden');
                    }else{
                        $('.ajaxBtn').addClass('hidden');
                    }
                    $('.ajaxBtn').removeClass('loading');
                    //ajaxMoreBtn.removeClass('loding');
                }
            });
            return this;
        })
    }

    function _initLinks(){
        $('.whisper a').ajaxLink();
    }

    function _initWhisper(o){

        action = 'product_search';
        key    = o.val();

        inputContainer.addClass('loading');
        $.ajax({
            url : ajaxurl,
            data : {'action': action, 'key': key },
            type : 'POST',
            success : function( data ){
                if(data.html != null){
                    $('.whisper').html(data.html);
                    if(!$('.whisper').hasClass('active')){
                        $('.whisper').addClass('active');
                    }
                    _initLinks();
                    if(inputContainer.hasClass('loading')){
                        inputContainer.removeClass('loading');
                        inputContainer.addClass('loaded');
                    }
                }else{
                    $('.whisper').html('');
                    $('.whisper').removeClass('active');
                    inputContainer.removeClass('loaded');
                }
                inputContainer.removeClass('loading');
            }
        });
        return this;
    }


    this.each(function () {
        _init();
    });

   return {
     reload:_init,
   };
};
}( jQuery ));

//$('#catalog-filter .input').ajaxWhisper();
$('.ajaxLinks').ajaxLink();
$('body').ajaxLoader();
